import React from 'react';
import ReactDOM from 'react-dom/client'
import './css/index.css'
import HdBlockList from './HdBlockList'
import reportWebVitals from './reportWebVitals'
import WpApi from './wpapi'
import HdBlock from './HdBlock'
import Loco from './Loco'
import User from './User'
import Quotations from './Quotations'
import HdSimpleBlock from './HdSimpleBlock'
import SupplierQuotationsList from './SupplierQuotationsList'
// HdBlockList
let hd_elm = document.getElementById('react-root-hd-blocklist') as HTMLElement;


if(hd_elm !== null){
    let params = hd_elm.parentElement!.querySelector('.params')!.textContent;
    if(params === null){
	console.log('error: params not found')
    }else{
	let data = JSON.parse(params)
	WpApi.nonce = data.nonce
	if(data.loco !== undefined){
	    Loco.nl = data.loco
	}
	User.tup = data.user
	let pid = data.post_id
	const root = ReactDOM.createRoot(hd_elm)
	if(data.view === "quotations"){
	    root.render(
		<React.StrictMode>
		    <Quotations
			is_simple_block={false}
			readonly_mode={false}
		      post_id={pid} user={data.user} view={data.view}/>
		    </React.StrictMode>
	    )
	}else{
	    root.render(
		<React.StrictMode>
		    <HdBlockList post_id={pid} user={data.user} view={data.view}/>
		    </React.StrictMode>
	    )
	}
    }
}
Array.from(document.getElementsByClassName("react-supplier-quotations-list")).forEach(
    function(elm, index, array) {
	let params = elm.parentElement!.querySelector('.params')!.textContent;
	if(params === null){
	    console.log('error: params not found')
	}else{
	    let data = JSON.parse(params)
	    WpApi.nonce = data.nonce
	    console.log(data);
	    if(data.loco !== undefined){
		Loco.nl = data.loco
	    }
	    const root = ReactDOM.createRoot(elm)
	    root.render(
		<React.StrictMode>
		    <SupplierQuotationsList supplier={data.supplier} />
		    </React.StrictMode>
	    )
	}
    }
)


// HdBlock for hd-simple-block
Array.from(document.getElementsByClassName("react-root-hd-simple-block")).forEach(
    function(elm, index, array) {
	let params = elm.parentElement!.querySelector('.params')!.textContent;
	if(params === null){
	    console.log('error: params not found')
	}else{
	    let data = JSON.parse(params)
	    WpApi.nonce = data.nonce
	    console.log(data);
	    const root = ReactDOM.createRoot(elm)
	    root.render(
		<React.StrictMode>
		    <HdSimpleBlock
			xpcondition={data.xpcondition} instr_field={data.instr_field}
			acfkey={data.acfkey} post_id={data.post_id} force_reload={data.force_reload}
		    />
		 </React.StrictMode>
	    )
	}	
    }
)

// HdBlock for single-block
Array.from(document.getElementsByClassName("react-root-hd-block")).forEach(
    function(elm, index, array) {
	let params = elm.parentElement!.querySelector('.params')!.textContent;
	if(params === null){
	    console.log('error: params not found')
	}else{

	    let data = JSON.parse(params)
	    WpApi.nonce = data.nonce
	    console.log(data)
	    if(data.loco !== undefined){
		Loco.nl = data.loco
	    }
	    User.tup = data.user
	    const root = ReactDOM.createRoot(elm)
	    if(data.view === "quotations"){
		let ro = data.readonly_mode === undefined ? false : data.readonly_mode
		root.render(
		    <React.StrictMode>
			<Quotations
			    is_simple_block={true}
			    readonly_mode={ro}
			    acfkey={data.acfkey}
			    post_id={data.post_id}
			    user={data.user} view={data.view}
			    supplier={data.supplier}
			/>
		    </React.StrictMode>
		)
	    }else{
		root.render(
		    <React.StrictMode>
			<HdBlock key="widget" params={data} post_id={data.post_id}  />
			</React.StrictMode>
		);
	    }
	}
    });


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
