import * as React from 'react';

import WpApi from './wpapi'
import StepAspect from "./StepAspect"
import FilteredChoices from './filtered_choices'
import Expander_helper from './Expander_helper'

interface MyProps {
    post_id: string,
    tup: any,
    options?:any,
    l2cluster:any,
    permission:any,
    cb_parent:   (e: any, cb: (r: any) => void) => void
}


type MyState = {
    supplier:string,
    supplier_choices: any,
    is_loading:boolean
};

interface cb_after_vote {
    (r:any): void;
}

class HdaStep extends React.PureComponent<MyProps,MyState> {
    
    private callback_after_vote :cb_after_vote = (x)=>{ console.log("dummy")}



    constructor(props: MyProps) {
        super(props)
	this.handle_cb = this.handle_cb.bind(this)
	this.handle_info_click = this.handle_info_click.bind(this)
	this.handle_select_supplier = this.handle_select_supplier.bind(this)

	this.state = {
	    supplier:'',
	    is_loading:false,
	    supplier_choices:{}
	}
    }

    componentDidMount(){
	if(this.with_supplier_dropdown()){
	    let atup = this.props.tup
	    let filter = new FilteredChoices(
		this.props.tup.section_name,
		this.props.l2cluster.name)

	    this.setState({
		supplier_choices: filter.filtered_choices(this.props.options.leverancier.buttons),
		supplier: atup.uitgevoerd_door
		
	    })
	  }
    }
    
    /*
     *  Post een HD-attr voor gekozen leverancier bij de Uitvoeringstatus van een Level-2 cluster
     */
    handle_select_supplier(e:any){
	console.log('selected supplier')
	let slug = e.target.value
	console.log(`changed dropdown to: ${slug}`)
	let d = {
	    post_id : this.props.post_id,
	    votetype: 'choice',
	    value: slug,
	    level2:this.props.l2cluster.name,
	    aspect: 'huidig',
	    acf_key: this.props.options.uitgevoerddoor.key,
	    is_final_field: "false",
	    trigger_recalc: "false",
	}
	console.log('set is loading true')
	this.setState({ is_loading:true})
	WpApi.do_post('/hd-attr',d, (r:any) => {
	    this.setState({
		is_loading:false,
		supplier:r.winner
	    })
	})
    }
    
    // send signal to parent ; to open the vote-grid
    // pass-on the signal from StepAspect
    handle_cb(e:any,cb:(r:any)=>void){
	this.callback_after_vote = cb;
	console.log("handle-cb")
	/*
	let label_txt = this.props.tup.label
	let slab = this.props.tup.section_label
	e.label = label_txt + ' ' + slab
	*/
	this.props.cb_parent(e,(r:any)=>{
	    console.log("received:")
	    console.log(r)
	    // reverse signal to StepAspect
	    this.callback_after_vote(r)
	})
    }
    public handle_info_click(){
	this.props.cb_parent({
	    cmd: 'open-info',
	    txt: this.props.tup.instructions
	},()=>{})
    }
    public render_instr(){
	if(this.props.tup.instructions){
	    return (<span className="cf-icon-btn"
		    data-balloon="Toon toelichting" data-balloon-pos="up"
		    onClick={this.handle_info_click}>
		<i className="bb-icon-rl bb-icon-info"/>
		</span>
		)
	}
    }
    with_supplier_dropdown(){
	if(this.props.options === null ||
	    this.props.options === undefined){
	    return false
	}
	return true
	
    }
    render_supplier_dropdown(){
	let choices = this.state.supplier_choices
	let prefix = "Door welke leverancier:"
	if(Object.entries(choices).length ===0 ){
	    return null
	}
	let supplier = this.state.supplier
	if(supplier){
	    let value = choices[supplier]
	    return (
		<div className="aux-option">
		    <div className="label">{prefix}</div>
		    {value}
		</div>
	    )
	}
	return (
	    <div className="aux-option">
		<div className="label">{prefix}</div>
		<select style={{height:'unset'}} onChange={this.handle_select_supplier}>
		<option value="">-kies-</option>
		{Object.entries(choices).map(([cname,clabel]:[string,any],i:number) => {
		    return (<option key={i} value={cname}>{clabel}</option>)
		})}
	    </select>
		</div>
	)
    }


    public render(){
	let label_txt = this.props.tup.label
	let label_txt2 = this.props.tup.label
	let parent_lab = this.props.tup.section_label

	if(this.props.l2cluster){
	    parent_lab = this.props.l2cluster.label
	}

	let m = /^([^{]+){parent}/.exec(this.props.tup.label)


	if(m){
	    let lab1 = m[1].trim()
	    label_txt = (<span>{lab1}: <i>{parent_lab}</i></span>)
	    label_txt2 = (<span>{lab1} <i>{parent_lab}</i></span>)
	}

	if(this.props.tup.label === ''){
	    return this.render_body("block",label_txt2)
	}else{
	    let exp = new Expander_helper();

	    return (
		<div className="step with-expand collapsed" acf-key={this.props.tup.key}>
		    <div className="header"  onClick={exp.toggle_expand}>
		        <div className="pre-icon"></div>
		        <div className="label">{label_txt} </div>
			{this.render_instr()}
		    </div>
		    {this.render_body("body",label_txt2)}
		</div>
	    )
	    
	}
    }
    render_body(class_name:string,label_txt:string){
	return (
	    <div className={class_name}>
		{this.with_supplier_dropdown() ? this.render_supplier_dropdown() : null}
		<div className="aspects two-columns">
		    <StepAspect
			key='huidig' post_id={this.props.post_id}
			label={label_txt} l2cluster={this.props.l2cluster}
			stup={this.props.tup}
			permission={this.props.permission.huidig}
			aspect='huidig' tup={this.props.tup.huidig} acfkey={this.props.tup.key}
			cb_parent={this.handle_cb} />
		    <StepAspect
			key='voorstel' post_id={this.props.post_id}
			label={label_txt} l2cluster={this.props.l2cluster}
			stup={this.props.tup}
			permission={this.props.permission.voorstel}
			aspect='voorstel' tup={this.props.tup.voorstel} acfkey={this.props.tup.key}
			cb_parent={this.handle_cb} />
		</div>
	    </div>
	)
    }
}
export default HdaStep;
