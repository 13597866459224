import * as React from 'react';

import Loco from './Loco'
import HdaStep from './HdaStep';
import Bouwdelen from "./Bouwdelen"
import DerivedValueStep from "./DerivedValueStep"
import V1Inputs from "./V1Inputs"
import CollabNumberInput from "./CollabNumberInput"

import SubSection from "./SubSection"
import QuotationStep from './QuotationStep'
import WpApi from './wpapi'
import Expander_helper from './Expander_helper'

interface MyProps {
    post_id: string,
    user:any,
    tup: any,
    options:any,
    actiestappen:any,
    view:string,
    cb_parent:   (e: any, cb: (r: any) => void) => void
}


type MyState = {
    quotations:Array<any>,
    is_loading:boolean,
    comment_count:number,
    comments: Array<string>,
    completed:boolean
};

interface cb_after_vote {
    (r:any): void;
}

class HdaSection extends React.PureComponent<MyProps,MyState> {

    private current_row_key = "-";
    private callback_after_vote :cb_after_vote = (x)=>{ console.log("dummy")}

    constructor(props: MyProps) {
        super(props)
	this.handle_cb = this.handle_cb.bind(this)
	this.handle_info_click = this.handle_info_click.bind(this)
	this.handle_comment_click = this.handle_comment_click.bind(this)

	this.state = {
	    quotations:[],
	    is_loading:false,
	    comment_count:0,
	    comments:[],
	    completed:false
	}
    }


    // send signal to parent ; to open the vote-grid
    public handle_cb(e:any, cb:(r:any)=>void){
	this.callback_after_vote = cb;
	console.log("handle cb")
	if(e.cmd === "set-section-status"){
	    console.log(e)
	    this.setState({ completed : (e.val === "ready")})
	}else{
	    this.props.cb_parent(e,(r:any)=>{
		console.log("result after vote:")
		console.log(r)
		// reverse signal to Step
		this.setState({ completed : (r.section_status === "ready")})
		this.callback_after_vote(r)
	    })
	}
    }
    handle_info_click(){
	this.props.cb_parent({
	    cmd: 'open-info',
	    txt: this.props.tup.instructions
	},()=>{})
    }
    handle_comment_click(){
	console.log('comments-clicked')
	let lbl = 'Opmerkingen bij: ' +  this.props.tup.label
	this.props.cb_parent({
	    cmd: 'comment-vote',
	    acf_key_step: this.props.tup.key,
	    aspect:'huidig',
	    label: lbl
	},(r:any)=>{
	    console.log('callback after comment-vote')
	    let newtxt = r.txt
	    console.log(` add comment ${newtxt}`)
	    if(r.new_state){
		this.setState({
		    comments: r.new_state.map((x:any)=>x.value),
		    is_loading:false
		})
	    }
	})
    }
    public render_instr(){
	if(this.props.tup.instructions){
	    return (<div className="info-popup"><span className="cf-icon-btn"
		    data-balloon="Toon toelichting" data-balloon-pos="up"
		    onClick={this.handle_info_click}>
		<i className="bb-icon-rl bb-icon-info"/>
		</span></div>
		)
	}
    }
    show_step(tup:any){
	if(this.props.view === "hdquotations"){
	    return false
	}
	if(this.props.view === "buildingmeasures"){
	    return tup.cftype === 'hda-bouwdelen-stap'
	}
	return true
    }
    componentDidMount(){
	this.setState({
	    quotations: this.props.tup.quotations,
	    completed: (this.props.tup.value === 'ready')
	})

	if('l1comments' in this.props.tup){
	    let txt_array = this.props.tup.l1comments.map((x:any)=>x.value)
		this.setState({
		    comments: txt_array,
		    comment_count: this.props.tup.l1comment_count
		})
	    }

    }
    render_quotations(row:any){
	let opt = this.props.options
	let act = this.props.actiestappen

	let l1cluster = {
	    name: this.props.tup.name,
	    label: this.props.tup.label
	}
	let exp = new Expander_helper();

	return (
	    <div className="quotations with-expand collapsed">
		<div className="header" onClick={exp.toggle_expand}>
		   <div className="pre-icon"></div>
		   <div className="label">Offertes</div>
		</div>
<div className="body">
	    {this.state.quotations.map((tup:any,i:number) => {
		if(tup.level2 === row.name){
		    //return (<div>YUP </div>)
		    return <QuotationStep
		    key={i}
		    is_simple_block={false}
		    level2={{ name: row.name, label: row.label}}
		    level1={l1cluster}
		    permission={this.props.tup.permission}
		    options={opt.quotation}
		    actiestappen={act}

		    postid={Number(this.props.post_id)}
		    cb_parent={this.handle_cb}
		    tup={tup}
			/>
		}
	    })}
		<span className="cf-button" onClick={()=>this.clicked_add_quotation(row.name)}>{Loco.tr('add_quotation')}</span>
		</div>
	    </div>
	)
    }
    clicked_add_quotation(level2:string){
	let d = {
	    post_id: this.props.post_id,
	    acf_key: this.props.tup.key,
	    votetype: 'quotation',
	    level2: level2,
	    value: level2
	}
	this.setState({is_loading:true})

	WpApi.do_post('/hd-attr',d, (r) => {
	    r.status = 'kies'
	    r.uistate = 'new'
	    console.log(r);
	    this.setState(prevState => ({
		is_loading:false,
		quotations: [...prevState.quotations, r]
	    }))
	})

    }
    render_l2_block(l2tup:any,j:number,l2c:any){
	let q_opt = {
	    leverancier: this.props.options.quotation.leverancier,
	    uitgevoerddoor: this.props.tup.uitgevoerd_door
	}
	let q_opt2 :any = 
	    (l2tup.name === "uitvoering_status") ? q_opt : null

	return (
	    <HdaStep
	    options={q_opt2}
	    l2cluster={l2c}
	    cb_parent={this.handle_cb}
	    permission={this.props.tup.permission}
	    key={j} tup={l2tup} post_id={this.props.post_id} />
	)
    }
    /*
     *  part: (1 or 2)
     *    part=1: show all rows except "uitvoerings_status" 
     *    part=2: show only the row "uitvoerings_status" 
     */
    render_l2_blocks(row:any, part:number){
	let l2c = { label : row.label, name: row.name}
	return (
	    <div className="steps">
		{row.fields.map((l2tup:any, j:number) => {
		    if(part == 1){
			if(l2tup.name === "uitvoering_status"){return null}
		    }
		    else if(part == 2){
			if(l2tup.name !== "uitvoering_status"){return null}
		    }else{
			return null
		    }
		    return this.render_l2_block(l2tup,j,l2c)
		})}
	    </div>
	)
    }
    render_l2_cluster(row:any,i:number){
	let exp = new Expander_helper();
	let l2c = { label : row.label, name: row.name}
	return (
	    <div className="l2-cluster cluster with-expand collapsed" key={i}>
		<div className="header" onClick={exp.toggle_expand}>
		    <div className="pre-icon"></div>
		    <div className="label">{row.label}</div>
		</div>
		<div className="body">
		    {this.render_l2_blocks(row,1)}
		    {this.props.tup.with_quotations ? this.render_quotations(row) : null}
		    {this.render_l2_blocks(row,2)}
		</div>
	    </div>
	)
    }
    show_l1_comments(){
	if(!( 'l1comments' in this.props.tup)){
	    return false
	}
	return ['actionlist'].includes(this.props.view)
    }
    render_comments(){
	return this.show_l1_comments() ? (
    	    <div className="step remarks l1-remarks"  onClick={this.handle_comment_click} >
		<div className="text-with-btn">
		    <div className="text">
			{this.state.comments.map((txt:string,j:number)=> {
			    return (<div key={j} className="part" >{txt}</div>)
			})}
		    </div>
		    <span className="cf-count-btn"
			  data-balloon="Keuze en details" data-balloon-pos="up"> {this.state.comment_count}</span>
		</div>
	    </div>
	) : null
    }
    /*
     *  part: (1 or 2)
     *    part=1: show all rows except "uitvoerings_status" 
     *    part=2: show only the row "uitvoerings_status" 
     */
    render_l1_blocks(part:number){
	return this.props.tup.steps.map((tup:any, j:number) => {
	    if(part == 1){
		if(tup.name === "uitvoering_status"){return null}
	    } else if(part == 2){
		if(tup.name !== "uitvoering_status"){return null}
	    }

	    if(! this.show_step(tup)){
		return null
	    }
	    if( tup.cftype === 'hda-choice-stap'){
		// render level-1 steps
		let l2 = { name: 'NOT_LEVEL2' }
		return (<HdaStep
			l2cluster={l2}
			cb_parent={this.handle_cb}
			permission={this.props.tup.permission}
			key={j} tup={tup} post_id={this.props.post_id} />)
	    }else if( tup.cftype === 'sub-section'){
		//return this.render_subsection(j,tup)
		return (<SubSection key={j} tup={tup} cb_parent={this.handle_cb}
			permission={this.props.tup.permission}
			
		    />)
	    }else if( tup.cftype === 'hda-derived-field'){
		return (<DerivedValueStep tup={tup} key={j}/>)
	    }else if( tup.cftype === 'hda-bouwdelen-stap'){
		return (<Bouwdelen key={j}
			user={this.props.user}
			permission={this.props.tup.permission}
			cb_parent={this.handle_cb}
			post_id={this.props.post_id} tup={tup}  />)
	    }else if( tup.cftype === 'v1-inputs-with-comment'){
		return (<V1Inputs
			tup={tup} key={j}
			parent_label=""
			cb_parent={this.handle_cb}
		    /> )
	    }else{
		return null
	    }
	})
    }
    public render(){
	if(this.props.tup.cftype === 'hda-section'){
	    let exp = new Expander_helper();
	    
	    let label_txt = this.props.tup.label
	    if(this.props.view === "actionlist"){
		label_txt = this.props.tup.cft_identifier + '. ' + this.props.tup.label
	    }
	    return (
		<div className="section l1-cluster with-expand cluster collapsed"  acf-key={this.props.tup.key}>
		    <div className="header"  onClick={exp.toggle_expand}>
			<div className="pre-icon"></div>
			<div className="label">
			    {label_txt}
			    {this.state.completed ? ' - AFGEROND' : null}
			    {this.render_instr()}
			</div>
		    </div>
		    <div className="body">
		    {this.render_comments()}
			<div className="steps">
			    {this.render_l1_blocks(1)}
			</div>
		    {this.show_level2() ? (
			<div className="level2-clusters">
			    {this.props.tup.level2_clusters.map((row:any,i:number) => this.render_l2_cluster(row,i))}
			</div> ) : null }
			<div className="steps">
			    {this.render_l1_blocks(2)}
			</div>
			
  		</div>
		</div>
	    )
	}
    }
    show_level2(){
	if(this.props.view === "buildingmeasures"){
	    return false
	}
	return true
    }
}
export default HdaSection;
