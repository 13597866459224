import * as React from 'react'
import $ from 'jquery'
import WpApi from './wpapi'
import Loader from './Loader'
import './css/SupplierQuotations.css'
import SupplierQuotationsListRow from './SupplierQuotationsListRow'
import VoteGridModal from './modals/VoteGridModal'
import HistoryModal from './modals/HistoryModal'

interface MyProps {
    supplier: string,
}
type MyState = {
    loading:boolean,
    options: any,
    rows:Array<any>
}
interface cb_after_vote_event {
    (r:any): void;
}

class SupplierQuotationsList extends React.PureComponent<MyProps,MyState> {
    private fetchPromise ?: Promise<void>    
    private vote_popup =  React.createRef<VoteGridModal>()
    private history_popup =  React.createRef<HistoryModal>()
    private callback_after_vote_event :cb_after_vote_event = (x)=>{ console.log("dummy")}

    constructor(props: MyProps) {
        super(props)
	this.after_vote_event = this.after_vote_event.bind(this)	
	this.open_popup = this.open_popup.bind(this)
	this.click_col_header = this.click_col_header.bind(this)
	this.sort_rows = this.sort_rows.bind(this)
	
	this.state = {
	    rows: [],
	    options:{},
	    loading:true
	}
    }

    
    init(){
	this.setState({loading:true})
	if (this.fetchPromise) {
	    console.log(' already mounted previously')
	    return;
	}
	let d = {
	    supplier: this.props.supplier
	}
	this.fetchPromise = WpApi.do_get('/hd-quotations', d,(r) => {
	    this.setState({
		rows  : r.rows,
		options : r.options,
		loading:false
	    })
	})
	    

    }    
    componentDidMount(){
	console.log('did mount')
	this.init()
    }
    reset_filters(){
	console.log('reset-filters')
	$('.quotation .row').removeClass('verberg')
	$('.quotation.header input').val('');
	$('.quotation.header .hdr-label').removeClass('sorting-d')
	$('.quotation.header .hdr-label').removeClass('sorting-a')
	
	
    }
    change_filter_input(e:any){
	let name = e.target.closest('div').classList[0];
	$('.quotation .row').removeClass('verberg')

	let filters : {[k:string]: string } = {}
	document.querySelectorAll('.supplier-quotations-list .quotation.header input').forEach((e)=>{
	    let k = $(e).attr('name')
	    if(k !== undefined){
		let v = String( $(e).val()).toLowerCase()
		if(v){
		    filters[k] = v
		}
	    }
	});
	console.log(filters)
	
	// iterate rows
	document.querySelectorAll('.quotation .row').forEach((e) => {
	    Object.entries(filters).map(([name,val]:[string,string]) =>{
		let child = e.querySelector(`.${name}`)
		if(child){
		    let text = child.textContent ? child.textContent : ''
		    console.log(` text ${text}`)
		    if(!text.toLowerCase().includes(val)){
			e.classList.add('verberg')
		    }
		}
	    })
	});
    }
    click_col_header(e:any){
	
	let trg = e.target.closest('.hdr-label')
	if(trg.nodeName == 'INPUT'){
	    return;
	}
	let name = trg.classList[0]
	console.log(` col-header clicked name: ${name}`)

	let cur = 'none'
	
	if(trg.classList.contains('sorting-d')){
	    cur = 'sorting-d'
	}else{
	    if(trg.classList.contains('sorting-a')){
		cur = 'sorting-a'
	    }
	}
	$('.quotation.header >div').removeClass('sorting-a');
	$('.quotation.header >div').removeClass('sorting-d');

	console.log(`current mode: ${cur}`)
	if(cur === 'sorting-a' || cur === 'none'){
	    console.log('set sorting-d')
	    trg.classList.add('sorting-d')
	    this.sort_rows(name,true)
	}else{
	    console.log('set sorting-a')
	    trg.classList.add('sorting-a')
	    this.sort_rows(name,false)
	}
    }
    sort_rows(name:string, rev:boolean){
	console.log(`sort_rows ${name}`)
	let body = '.supplier-quotations-list > .body'
	//@ts-ignore
	$(body + ' .quotation').sort((a:HTMLElement,b:HTMLElement)=>{
	    let ta = $(a).find(`.row > .${name}`).text().toLowerCase();
            let tb = $(b).find(`.row > .${name}`).text().toLowerCase();
	    let ret = (ta > tb ? 1 : (ta < tb ? -1 : 0));
	    if(rev){
		return ret * -1;
	    }
	    return ret
	}).appendTo(body)
				     
    }

						    
    render_header(){
	let titles = {
	    date : 'Aangevraagd',
	    contact: 'Aanvrager',
	    adres: 'Adres',
	    postcode: 'Postcode',
	    level1: 'Maatregel-type',
	    level2: 'Maatregel',
	    status1: 'Status volgens bewoner',
	    status2: 'Status volgens leverancier',
	}
	return (
	    <div className="quotation header">
		<div className="pre-icon-h"></div>
		{Object.entries(titles).map(([name,label]:[string,string],i:number) => (
		    <div key={i} className={name + " hdr-label"} onClick={this.click_col_header}>
			<div className="label">{label}</div>
			<input name={name} type={"text"} onChange={this.change_filter_input} />
			</div>
		))}
	    </div>
	)
    }
    public open_popup(e:any, cb:(r:any)=>void){
	this.callback_after_vote_event = cb
	console.log(`open-popup cmd: ${e.cmd}`)
	console.log(e)
	let votetypes = [
	    'quotations-comment-vote',
	    'quotation-status-vote',
	    'choice-vote',
	    'comment-vote'
	]
	let history = [
	    'quotation-history',
	    'open-history'
	]
	if(votetypes.includes(e.cmd)){
	    this.vote_popup.current!.init(e)
	}else if(history.includes(e.cmd)){
	    this.history_popup.current!.init(e)
	}else{
	    console.log("ERROR unknown cmd")
	    return
	}
    }
    public after_vote_event(v:any){
	console.log(`got vote-event kind ${v.kind}`)
	console.log(v)
	let d :{[name:string]:string}= {
	    post_id: v.post_id,
	    votetype: v.kind,
	}
	if(v.votetype !== undefined){
	    d.votetype = v.votetype
	}
	if(v.acf_key !== undefined){
	    d.acf_key = v.acf_key
	}
	if(v.kind === "add_comment"){

	    d.aspect = v.aspect
	    d.value = v.txt
	    d.level2 = v.level2
	    if(v.on_attr_id !== undefined){
		d.on_attr_id = v.on_attr_id
	    }
	}
	else if(v.kind === "update_comment"){
	    d.attr_id =  v.attr.attr_id
	    d.value = v.comment
	    if(v.on_attr_id !== undefined){
		d.on_attr_id = v.on_attr_id
	    }
	}
	else if(v.kind === 'remove_vote'){
	    d.attr_id = v.attr_id
	}
	else if(v.kind === 'chosen_option'){
	    d.value = v.value
	    d.is_final_field = v.is_final_field
	    d.trigger_recalc = v.trigger_recalc
	    d.aspect = v.aspect
	    if(d.acf_key === ""){
		d.acf_key = v.acf_key_select
	    }
	    if(v.on_attr_id !== undefined){
		d.on_attr_id = v.on_attr_id
	    }
	    d.level2 = v.level2
	    
	}
	else{
	    console.log('error unknown kind')
	    return
	}
	console.log(d)
	WpApi.do_post('/hd-attr',d, (r) => {
	    this.callback_after_vote_event(r)
	})
	
    }
    render_rows(){
	return this.state.rows.map((row, i) => {
	    return (
		<SupplierQuotationsListRow
		    key={i} row={row}
		    cb_parent={this.open_popup}
		    options={this.state.options}
		/>
	    )
	})
    }
    render(){
	return (
	    <div className="supplier-quotations-list">

		
		<span className="cft-button-simple reset-filters" onClick={this.reset_filters}>Reset filters</span>
		<div className="hdr">
		    {this.render_header()}
	        </div>
		{this.state.loading ? <Loader /> : null}		
		<div className="body">
		    {this.render_rows()}
		</div>

		<VoteGridModal ref={this.vote_popup} cb_parent={this.after_vote_event} post_id="undef" />
		<HistoryModal ref={this.history_popup}  post_id="undef" />
	    </div>
	)
    }
}
export default SupplierQuotationsList

