class FilteredChoices{
    level1:string
    level2:string
    
    constructor(l1:string,l2:string){
	this.level1 = l1
	this.level2 = l2
    }
    filtered_choices(buttons:Array<any>){
	let l1name = this.level1
	let l2name = this.level2

	console.log(` filtering ${l1name} > ${l2name} `)

	let ret :{[name:string]:string}= {};

	let btns = buttons.filter((el:any,i:number)=>{
	    //console.log(` .. btn ${el.name}`)
	    let logic = el.conditional_logic
	    var show = false
	    if(logic){
		for(var rule of logic){
		    for(var r of rule){
			//console.log(` ... logic ${r.value}`)
			let [l1,l2] = r.value.split('.')
			if(l1 === l1name){
			    //console.log('  .... L1 ok')
			    if(l2 === '*' || l2 === l2name){
				show = true
			    }
			}
		    }
		}
	    }else{
		return true
	    }
	    return show
	})
	for(var b of btns){
	    ret[b.name] = b.label
	}
	return ret
    }
}

export default FilteredChoices;


