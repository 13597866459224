import * as React from 'react'
import WpApi from './wpapi'
import QuotationStep from './QuotationStep'
import Loader from './Loader'
import Loco from './Loco'
import FilteredChoices from './filtered_choices'

interface MyProps {
    tup:any,
    postid:number,
    is_simple_block:boolean,
    readonly_mode:boolean,
    options:any,
    supplier?:string,
    actiestappen:any,
    headers:any,
    cb_parent:   (e: any, cb: (r: any) => void) => void
}

type MyState = {
    rows:Array<any>,
    show_add_new:boolean,
    is_loading:boolean
}
interface cb_after_vote {
    (r:any): void;
}

class QuotationSection extends React.PureComponent<MyProps,MyState> {
    private callback_after_vote :cb_after_vote = (x)=>{ console.log("dummy")}
    constructor(props: MyProps) {
        super(props)
	this.handle_select_change = this.handle_select_change.bind(this)
	//this.render_row = this.render_row.bind(this)
	this.handle_cb = this.handle_cb.bind(this)

	
	this.state = {
	    rows:[],
	    show_add_new:true,
	    is_loading:false
	}	
    }
    componentDidMount(){
	this.setState({
	    rows: this.props.tup.quotations
	})
    }
    
    public handle_select_change(e:any){

	if(this.props.readonly_mode){
	    console.log("readonly-mode")
	    return;
	}
	console.log('select-change adding a row');
	let v  = e.target.value
	e.target.value = ''
	let choices = this.props.tup.maatregel.choices;
	let d = {
	    post_id: this.props.postid,
	    acf_key: this.props.tup.key,
	    votetype: 'quotation',
	    value: v
	}
	this.setState({is_loading:true})
	WpApi.do_post('/hd-attr',d, (r) => {
	    console.log(r);
	    r.status = 'kies'
	    this.setState(prevState => ({
		is_loading:false,
		show_add_new:false,
		rows: [...prevState.rows, r]
	    }))
	    
	});
	// add row in UI
	/*
	let label = choices[v];
	*/
    }

    /*
    xrender_row(tup:any,i:number){
	console.log(tup)
	let l1cluster = {
	    name: this.props.tup.name,
	    label: this.props.tup.label
	}
	

	return <QuotationStep
	key={i}
	is_simple_block={this.props.is_simple_block}

	permission={this.props.tup.permission}
	options={this.props.options}
	actiestappen={this.props.actiestappen}
	level1={l1cluster}
	level2={{ name: tup.name, label: tup.label}}

	postid={this.props.postid}
	cb_parent={this.handle_cb}
	tup={tup}
	    />
	
    }
*/
    /*
    get_maatregel_choices(){
	let ret : { [string: string]: string } = {}
	Object.entries(this.props.tup.maatregel.choices).map(( [k,v]:[string,any],i:number) => {
	    if(this.props.supplier === "van_de_bunt" && this.props.tup.key === "field_65f83761c5e49"){
		if( k === "spouw") {
		    ret[k] = v
		}
	    }else{
		ret[k] = v
	    }
	});
	return ret
    }
    public render_select(){
	let choices = this.get_maatregel_choices()


	return this.state.show_add_new ? (
	    <div className="add-row"> <span> {Loco.tr('add_quotation')} </span>
		<select style={{height:'unset'}} onChange={this.handle_select_change} disabled={this.props.readonly_mode}>
		<option value="" >-kies-</option>
		{Object.entries(choices).map(([cname,clabel]:[string,any],i:number) => {
		    return (<option key={i} value={cname}>{clabel}</option>)
		})}

 	    </select>
		</div>
	) : null
    }
*/
    public handle_cb(e:any, cb:(r:any)=>void){
	this.callback_after_vote = cb;
	console.log("handle cb")
	if(e.cmd === "set-quotation-status"){
	    console.log(e)
	    console.log(`changed dropdown to: ${e.value}`)
	    let d = {
		post_id : this.props.postid,
		votetype: 'choice-on-attr',
 		value: e.value,
		acf_key: e.acf_key,
		aspect: 'huidig',
		is_final_field: "false",
		trigger_recalc: "false",
		on_attr_id: e.on_attr_id
	    }
	    console.log(d)
	    
	    WpApi.do_post('/hd-attr',d, (r:any) => {
		console.log(r)
		this.setState({show_add_new:true})
		this.callback_after_vote(r)
	    })
	    
	}else{
	    // pass signal to parent
	    this.props.cb_parent(e,(r:any)=>{
		console.log("result after vote:")
		console.log(r)
		// reverse signal to caller
		this.callback_after_vote(r)
	    })
	}
	
    }
    render_l2_cluster(row:any,i:number){
	let filter = new FilteredChoices(this.props.tup.name,row.name)
	let supplier_choices = filter.filtered_choices(this.props.options.quotation.leverancier.buttons)
	// only show level2 options which are relevant for given supplier
	if(this.props.supplier !== undefined && this.props.supplier !== ''){
	    if(!( this.props.supplier in supplier_choices)){
		return null
	    }
	}
	return (
	    <div className="l2-cluster cluster " key={i}>
		<div className="header">
		   <div className="label">{row.label}</div>
		</div>
		<div className="body">
		{this.props.tup.with_quotations ? this.render_quotations(row) : null}
	    </div>
	    </div>
	)
    }
    render_quotations(row:any){
	let opt = this.props.options
	let act = this.props.actiestappen
	
	let l1cluster = {
	    name: this.props.tup.name,
	    label: this.props.tup.label
	}

	return (
	    <div className="quotations"> 
	    {this.state.rows.map((tup:any,i:number) => {
		if(tup.level2 === row.name){
		    //return (<div>YUP </div>)

		    return <QuotationStep
		    key={i}
		    	is_simple_block={this.props.is_simple_block}
		    level1={l1cluster}
		    level2={{ name: row.name, label: row.label}}
		    permission={this.props.tup.permission}
		    options={opt.quotation}
		    actiestappen={act}
		    postid={this.props.postid}
		    cb_parent={this.handle_cb}
		    tup={tup}
			/>
		    /**/
		}
	    })}
		<span className="cf-button" onClick={()=>this.clicked_add_quotation(row.name)}>{Loco.tr('add_quotation')}</span>
	    </div>
	)
    }
    clicked_add_quotation(level2:string){
	let d = {
	    post_id: this.props.postid,
	    acf_key: this.props.tup.key,
	    votetype: 'quotation',
	    level2: level2,
	    value: level2
	}
	this.setState({is_loading:true})

	WpApi.do_post('/hd-attr',d, (r) => {
	    r.status = 'kies'
	    r.uistate = 'new'
	    console.log(r);
	    this.setState(prevState => ({
		is_loading:false,
		rows: [...prevState.rows, r]
	    }))
	})

    }
    
    render(){
	var loader = (<Loader/>)
	let label = this.props.tup.label

	return (
	    <div className="quotations-with-implementation">
		<div className="section cluster quotation"  cat-id={this.props.tup.term_id}>
		    <div className="header">
			<div className="label"> { label } </div>
		    </div>
		    <div className="body">
	            {this.state.is_loading ? loader : null }
		    
		    

		    <div className="level2-clusters">
		{this.props.tup.level2_clusters.map((row:any,i:number) => this.render_l2_cluster(row,i))}
	    </div>
		</div>

		</div>

	    </div>
	)

    }
}

export default QuotationSection
