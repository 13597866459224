class Conditional_dropdown {
    select_with_cond_logic(
	buttons:Array<any>,
	show_all:boolean,
	winner:string,
	placeholder:string,
	pre_text:string,
	handle_change:(e:any)=>any,
	extra_filter?:(key:string)=>boolean,

    ){
	let buttons2 = []
	if(extra_filter !== undefined){
	    buttons2 = buttons.filter((x:any)=>{
		return extra_filter(x.name)
	    })
	}else{
	    buttons2 = buttons
	}
	let btns = buttons2.map((el:any,i:number)=>{
	    let logic = el.conditional_logic
	    var show = false
	    if(show_all){
		show = true
	    }else if(logic){
		for(var rule of logic){
		    for(var r of rule){
			if(winner === r.value){
			    show = true
			}
		    }
		}
	    }
	    return show ? (<option key={i} value={el.name}>{el.label}</option>) : null
	})
	let btns2 = btns.filter((x:any)=>x!=null)

	if(btns2.length === 0){
	    // afgerond / no options, hide select
	    return (<div className="nop">  </div>)
	}

	// fixme: wijzig status / wijzig Advies
	return (
	    <div className="cft-select-wrap">
		<div className="label">{pre_text}</div>
		<select  onChange={handle_change}>
		<option>{placeholder} </option>
		    {btns}
		</select>
	    </div>
	)
    }
					    
}    
export default Conditional_dropdown;
