class Expander_helper {
    toggle_expand(e:any){
	let qel = e.target.closest('.with-expand');

	let is_collapsed = qel.classList.contains('collapsed');
	
	console.log(' is collapsed ?  ' + (is_collapsed ? 'y' : 'n'))
	//document.querySelectorAll('.quotation').forEach((x)=>x.classList.add('collapsed'))
	console.log(qel)
	if(is_collapsed){
	    qel.classList.remove('collapsed')
	}else{
	    qel.classList.add('collapsed')
	}
    }
}
export default Expander_helper
